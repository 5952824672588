<template>
  <div id="app">
    <div id="wrapper">
      <template v-if="!isLoading">
        <foradult v-if="['category'].indexOf($route.name) > -1"></foradult>
        <cookiesmsg v-if="['orderstatus', 'details', 'delivery'].indexOf($route.name) == -1"></cookiesmsg>
        <OrderCard v-if="!isClosed && ['/','home', 'category', 'item'].indexOf($route.name) > -1"></OrderCard>
      </template>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content" class="mobile" style="background: #F8F8F8;">
          <div class="container p-0">
            <preloader v-if="isLoading"/>
            <router-view v-if="!isLoading"/>
            <div id="empty-plug" v-if="['/','home', 'category', 'item'].indexOf($route.name) > -1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css">
.disconnected {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  color: white;
  font-size: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script type="text/javascript">

import { getPlace } from '@/lib/utils'

export default {
  data: function () {
    return {
      user: null,
      isClosed: false,
      isLoading: true
    }
  },
  components: {
    'OrderCard': () => import('./components/card'),
    'cookiesmsg': () => import('./components/cookiesmsg'),
    'foradult': () => import('./components/foradult'),
  },
  computed: {
    userActive: function () {
      return this.$root.user
    }
  },
  mounted: function () {
    this.$root.$on('object:closed', (isClosed) => {
      this.isClosed = isClosed;
    })
    this.$root.$on("loaded", (state) => {
      this.isLoading = state;
    })
    this.$root.$on('user', user => {
      this.user = user;
    });

    // console.log('comp', this);

    this.$root.$on('user:logout', () => {
      this.user = null;
    })

    const place = getPlace()
    let lsuser = localStorage.getItem(`user_${place}`);

    if (lsuser) {
      this.user = JSON.parse(lsuser);
    }
  }
}
</script>

